$(() => {
  $(function() {
    $('.open').on('click', function() {
      $(this).closest(".show-video").find('.overlay, .modalWindow').fadeIn();
    });
    $('.close-btn').on('click', function() {
      $(this).closest('.show-video').find('.overlay, .modalWindow').fadeOut();
    });
  });

  $('.close-btn',).on('click', function () {
    const video = $(this).closest('.overlay').find('.modalWindow');
    video.detach();
    $(video).appendTo(this);
  });

  let width = innerWidth;
  let distance = (width - 320) / 2;
  let value = 300 - distance;
  $(".pricing-plan-wrap").scrollLeft(value);
})
